import { useHover } from 'react-use'
import { copyCurrentWindowLocation } from '@/utils'
import { shareToSocial } from '@/utils/social'
import { Link } from '@vhl/icons/post'
import { Zalo, Facebook } from '@vhl/icons/social'
import LikeButton from './LikeButton'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

function CopyLinkIcon() {
  const icon = () => (
    <Link
      className="cursor-pointer stroke-gray-400"
      width={32}
      height={32}
      onClick={() => {
        copyCurrentWindowLocation()
        setIsClicked(true)
        setTimeout(() => {
          setIsClicked(false)
        }, 1500)
      }}
    />
  )
  const [hoverable, hovered] = useHover(icon)
  const [isClicked, setIsClicked] = useState(false)
  return (
    <div className="relative stroke-gray-900">
      <AnimatePresence exitBeforeEnter>
        {hovered && (
          <motion.span
            key="link-icon"
            initial={{ opacity: 0, scaleY: 0 }}
            animate={{ opacity: 1, scaleY: 1 }}
            exit={{ opacity: 0, scaleY: 0 }}
            className="absolute min-w-[100px] -top-9 -left-2"
          >
            <div className="relative">
              <span className="px-2 py-1 text-white bg-gray-800 caption-sm">
                {isClicked ? 'Đã copy' : 'Copy link'}
              </span>
              <svg
                className="absolute left-1/4"
                style={{ top: '90%' }}
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99968 9L0.9375 0L13.0619 1.05995e-06L6.99968 9Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </motion.span>
        )}
      </AnimatePresence>
      {hoverable}
    </div>
  )
}

const Interactive = ({ postId }: { postId: number }) => {
  return (
    <div className="sticky top-0 flex flex-col space-y-16">
      <LikeButton postId={postId} direction="column" />
      <CopyLinkIcon />
      <Facebook
        className="transition duration-300 cursor-pointer fill-gray-400 hover:scale-110"
        width={32}
        height={32}
        onClick={() => shareToSocial('facebook', window.location.href)}
      />
      <Zalo
        className="transition duration-300 cursor-pointer hover:scale-110"
        width={32}
        height={32}
        onClick={() => shareToSocial('zalo', window.location.href)}
      />
    </div>
  )
}

export default Interactive
